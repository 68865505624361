import { Center, Text, Stack, VStack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import TimeUnit from './TimeUnit';


const getTimeLeft = (target: Date) => {
    const now = new Date();
    const difference = target.getTime() - now.getTime();

    if (difference <= 0) {
        return {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0
        };
    }

    const seconds = Math.floor(difference / 1000) % 60;
    const minutes = Math.floor(difference / 1000 / 60) % 60;
    const hours = Math.floor(difference / (1000 * 60 * 60)) % 24;
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));

    return { days, hours, minutes, seconds };
};

interface CountdownProps {
    label: string;
    target: Date;
}

const Countdown = ({ label, target }: CountdownProps) => {
    const [timeLeft, setTimeLeft] = useState(getTimeLeft(target));

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTimeLeft(getTimeLeft(target));
        }, 1000);

        // Clear the interval on component unmount
        return () => clearInterval(intervalId);
    }, [target]);

    return (
        <Center h={'100vh'}>
            <VStack gap={{ base: 8, sm: 6, lg: 2 }}>
                <Text fontFamily={'greycliff-cf, sans-serif'} fontSize={'2.5rem'} fontWeight={600} textAlign={'center'}>{label}</Text>
                <Stack gap={{ base: 4, sm: 0, lg: 28 }} direction={{ base: 'column', sm: 'row' }}>
                    <TimeUnit number={timeLeft.days} unit={'Days'} />
                    <TimeUnit number={timeLeft.hours} unit={'Hours'} />
                    <TimeUnit number={timeLeft.minutes} unit={'Minutes'} />
                    <TimeUnit number={timeLeft.seconds} unit={'Seconds'} />
                </Stack>
            </VStack>
        </Center>
    );
};

export default Countdown;