import * as React from "react"
import {
    ChakraProvider,
} from "@chakra-ui/react"
import Countdown from "./components/Countdown"
import Sidebar from "./components/Sidebar"
import theme from "./theme"

export const App = () => (
    <ChakraProvider theme={theme}>
        <Sidebar />
        <Countdown label={'Bell arrives in Toronto 🫶🏻'} target={new Date('2024-08-06T19:45:00-04:00')} />
    </ChakraProvider>
)
