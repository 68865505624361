import { VStack, Text, HStack, Box } from '@chakra-ui/react';
import React from 'react';
import ReactTextTransition from 'react-text-transition';

interface TimeUnitProps {
    number: number;
    unit: string;
}

const TimeUnit = ({ number, unit }: TimeUnitProps) => {
    return (
        <VStack w={{ base: '8rem', sm: '12.5rem', lg: '15rem' }} textAlign={'center'}>
            <HStack fontFamily={'glodok, sans-serif'} textAlign={'center'}>
                {
                    number.toString().split('').map((digit, i) => (
                        <Box w={{ base: '3rem', sm: '3.75rem', lg: '7.5rem' }} fontSize={{ base: '4rem', sm: '5rem', lg: '10rem' }} lineHeight={{ base: '4rem', sm: '5rem', lg: '10rem' }}>
                            <ReactTextTransition key={i} translateValue={'70%'} inline>
                                {digit}
                            </ReactTextTransition>
                        </Box>
                    ))
                }
            </HStack>
            <Text fontFamily={'greycliff-cf, sans-serif'} textTransform={'lowercase'} fontSize={['1rem', '1.5rem']}>{unit}</Text>
        </VStack>
    );
};

export default TimeUnit;