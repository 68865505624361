import { Box } from '@chakra-ui/react';
import React from 'react';
import { ColorModeSwitcher } from '../ColorModeSwitcher';

const Sidebar = () => {
    return (
        <Box position={'fixed'} top={0} right={0}>
            <ColorModeSwitcher />
        </Box>
    );
};

export default Sidebar;